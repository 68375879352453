<template>
  <v-card v-if="!lodash.isEmpty(evento) && !loading">
    <!-- Titulo -->
    <v-card-title
      class="px-4 pt-2 pb-2 subtitle-1 d-flex align-center justify-space-between primary white--text"
    >
      <div class="d-flex align-center">
        <div class="mr-2 mr-md-4">
          <v-icon :large="$vuetify.breakpoint.mdAndUp" color="anchors">
            {{ evento.icone }}
          </v-icon>
        </div>
        <div>
          <div
            :class="{ 'body-2': $vuetify.breakpoint.mdAndDown }"
            v-if="evento.cliente"
          >
            {{ evento.cliente.toUpperCase() | excerpt(30) }}
          </div>
          <div class="body-2" style="line-height: 1.1;">
            {{ evento.clientetelefone }}
            {{ evento.clientecelular && evento.clientetelefone ? "/" : "" }}
            {{ evento.clientecelular }}
          </div>
        </div>
      </div>

      <v-btn icon @click="voltarEventos">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-card-title>

    <v-list tile class="lightgray text-uppercase pa-0">
      <v-list-item-group
        color="toolbar"
        class="d-flex align-center justify-start"
      >
        <v-list-item
          to="informacoes"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">info</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">
              {{ $tc("global.informacao", 2) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="financeiro"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">monetization_on</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">
              Financeiro
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="historico"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">history</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">Histórico</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="conexoes"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">router</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">Conexões</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="notas"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">speaker_notes</v-icon>
          </v-list-item-action>
          <v-list-item-content
            style="flex-wrap: nowrap;"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-list-item-title class="mb-0 caption">{{
              $tc("global.nota", 2)
            }}</v-list-item-title>
            <v-avatar
              class="ml-2 caption"
              color="anchors"
              size="20"
              max-width="20px"
            >
              <span class="white--text caption ">
                {{ notas.length ? notas.length : "0" }}
              </span>
            </v-avatar>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="materiais"
          active-class="active-event"
          style="max-height: 40px !important; "
          v-if="modulo_estoque"
        >
          <v-list-item-action>
            <v-icon size="22">store</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">{{
              $tc("eventos.material", 2)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>

        <v-list-item
          to="tarefas"
          active-class="active-event"
          style="max-height: 40px !important; "
        >
          <v-list-item-action>
            <v-icon size="22">view_list</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title class="caption">{{
              $tc("eventos.tarefas")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider vertical></v-divider>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <!-- ------------------------------------------------- -->
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <v-card-actions
      class="toolbar pa-1 d-flex align-center justify-space-between"
    >
      <div>
        <v-chip
          v-if="evento.cidade"
          class="mr-2 py-0"
          color="toolbar"
          label
          text-color="white"
        >
          <v-icon left>location_on</v-icon>
          {{ evento.cidade }}
        </v-chip>
        <v-chip
          v-if="evento.protocolo"
          class="mr-2 py-0"
          color="toolbar"
          label
          text-color="white"
        >
          <v-icon left>horizontal_split</v-icon>
          {{ evento.protocolo }}
        </v-chip>
      </div>
      <v-tooltip v-if="evento.created_at" left>
        <template v-slot:activator="{ on }">
          <v-btn
            color="white"
            class="caption text-lowercase"
            text
            dark
            v-on="on"
          >
            {{ evento.created_at | moment("from", "now") }}
          </v-btn>
        </template>
        <span>{{ evento.created_at | moment("DD/MM/YYYY HH:mm:ss") }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
  <v-card v-else class="pa-8">
    <Carregando />
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getEventosNotasPorEvento } from "@/services/api/eventos-notas.api.js";

export default {
  name: "Evento",
  props: ["eventoId"],
  data: () => ({
    evento: {},
    loading: false,
    notas: [],
  }),
  computed: {
    ...mapState("Tocsconfig", {
      modulo_estoque: (state) => state.modulo_estoque,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueEvento() {
      return this.getAccess("vueEvento", "visualizar");
    },
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    ...mapState("Eventos", {
      status: (state) => state.status,
    }),
    ...mapState("Evento", {
      original: (state) => state.evento,
    }),
    valid() {
      let valid = false;
      if (this.evento.titulo) {
        valid = true;
      }
      return valid;
    },

    evento_id() {
      return this.$route.params.eventoId;
    },
  },
  watch: {},
  methods: {
    excerptClient(text) {
      if (!text) return;
      if (text.length > 18 && this.$vuetify.breakpoint.mdAndDown) {
        return text.substr(0, 18) + "...";
      } else {
        return text;
      }
    },
    voltarEventos() {
      if (this.$route.meta.voltar.name === "Cliente") {
        this.$router.push({
          name: this.$route.meta.voltar.name,
          params: { clienteId: this.evento.cliente_id },
        });
      } else {
        if (this.$route.matched[0].path) {
          if (this.$store.state.query) {
            this.$router.push({
              path: this.$route.matched[0].path,
              query: this.$store.state.query,
            });
          } else {
            this.$router.push(this.$route.matched[0].path);
          }
        } else {
          if (this.$store.state.query) {
            this.$router.push({
              path: this.$route.matched[1].path,
              query: this.$store.state.query,
            });
          } else {
            this.$router.push(this.$route.matched[1].path);
          }
        }
      }
    },
    getEventosNotas() {
      getEventosNotasPorEvento(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.notas = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions("Evento", {
      getEvento: "getEvento",
      reset: "reset",
    }),
  },
  created() {
    if (this.vueEvento) {
      this.getEvento(this.evento_id).then(() => {
        this.evento = { ...this.original };
      });
      this.getEventosNotas();
    } else {
      this.voltarEventos();
    }
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

::v-deep .headline {
  input {
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}

::v-deep .chip100 {
  .v-chip__content {
    width: 100% !important;
  }
}

.active-event {
  border-bottom: 2px solid #208c6e;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-list-item {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    .v-list-item__action {
      margin-right: 0px !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .v-list-item {
    // flex: 0 1 auto !important;
    padding-left: 10px;
    padding-right: 10px;

    .v-list-item__action {
      margin-right: 10px !important;
    }
  }
}
</style>
